import axios from 'axios';
import { BASE_URL, ORDER_API_PATH, PRODUCT_API_PATH } from './constants';
import { getBusinessDataLink, getPremiumStatusLink } from './utils/links';

export const createOrder = (instance, data: { productId: string }, url = BASE_URL) => {
  return axios
    .post(`${url}${ORDER_API_PATH}`, data, { headers: { Authorization: instance } })
    .then((resp) => resp.data);
};

export const createProduct = (instance, data, url = BASE_URL) => {
  return axios
    .post(`${url}${PRODUCT_API_PATH}`, data, { headers: { Authorization: instance } })
    .then((resp) => resp.data);
};

export const getProduct = (instance, productId, url = BASE_URL) => {
  return axios
    .get(`${url}${PRODUCT_API_PATH}/${productId}`, { headers: { Authorization: instance } })
    .then((resp) => resp.data);
};

export const updateProduct = (instance, data, productId, url = BASE_URL) => {
  return axios
    .put(`${url}${PRODUCT_API_PATH}/${productId}`, data, { headers: { Authorization: instance } })
    .then((resp) => resp.data);
};

export const updateProductCurrencyFromSettings = async (instance, productId) => {
  const [
    product,
    {
      properties: { paymentCurrency: currency },
    },
  ] = await Promise.all([getProduct(instance, productId), getBusinessData(instance)]);
  product.product.currency = currency;
  return updateProduct(instance, product, productId);
};

export const getBusinessData = (instance) => {
  return axios.get(getBusinessDataLink(), { headers: { Authorization: instance } }).then((resp) => resp.data);
};

export const getPremiumStatus = (instance: string, baseUrl = BASE_URL) => {
  return axios
    .get(getPremiumStatusLink(baseUrl), { headers: { Authorization: instance } })
    .then((resp) => (resp as any).data.canAcceptOnlinePayments)
    .catch(() => true);
};

export async function makeRequestForOrder(instance, productId, url) {
  return createOrder(instance, { productId }, url);
}
