export const BASE_URL = 'https://cashier.wixapps.net';
export const ORDER_API_PATH = '/_api/payment-paybutton-web/paybutton/v2/orders';
export const PRODUCT_API_PATH = '/_api/payment-paybutton-web/paybutton/v2/products';
export const panelHelpId = '48d2426a-56ca-4b09-ba4b-1f709ad6debb';
export const APP_ID = 'cdd4b6d5-6fb4-4bd1-9189-791244b5361e';
export const POP_UP_LINK = 'https://cashier.wixapps.net/payment_app/premium-popup';
export const NO_PAYMENTS_POP_UP_LINK = 'https://cashier.wixapps.net/payment_app/no-payments-popup';

// TODO: Check if it's possible to replace with wixCodeApi enums
export enum ViewMode {
  PREVIEW = 'Preview',
  SITE = 'Site',
}

// TODO: Check if it's possible to replace with wixCodeApi enums
export enum UserRole {
  ADMIN = 'Admin',
}

export const createDefaultProduct = (currency, name) => ({
  product: {
    currency,
    name,
    price: 1,
  },
});

export const createSettingsPanelConfig = ({
  instance,
  appDefinitionId,
  helpId,
  componentRef,
  productId,
  buttonLabel,
  settingsTitle,
  startLoadTime,
}) => {
  return {
    componentRef,
    title: settingsTitle,
    width: 288,
    height: 546,
    helpId,
    url: `https://manage.wix.com/pay-button-settings?instance=${instance}`,
    initialData: {
      productId,
      buttonLabel,
      appDefinitionId,
      componentRef,
      startLoadTime,
    },
  };
};

enum DeviceType {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
  Tablet = 'Tablet',
}

export const MODAL_SIZE = {
  [DeviceType.Desktop]: {
    width: 750,
    height: 550,
  },
  [DeviceType.Tablet]: {
    width: 750,
    height: 550,
  },
  // workaround to open modal to 100%, openModal doesn't support string values
  // https://wix.slack.com/archives/CAKBA7TDH/p1604926273350300?thread_ts=1604922926.343800&cid=CAKBA7TDH
  [DeviceType.Mobile]: {
    width: 4000,
    height: 4000,
  },
};
